.custom-select-dropdown {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #e5e6eb;
}
.custom-select-dropdown .rc-virtual-list-holder-inner {
    padding: 4px 0;
}
.custom-select-dropdown .rc-virtual-list-holder-inner .ant-select-item-option {
    padding: 7px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #1d2129;
}
.custom-select-dropdown .rc-virtual-list-holder-inner .ant-select-item-option.ant-select-item-option-selected {
    background-color: rgba(36, 186, 98, 0.1);
    color: #24ba62;
}
